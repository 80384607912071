import formulae from "./calculus/formulae";

export default {
  // key :    name of the simulated measure' being accessed
  // getter : method to access base source and composite data
  // culture: what culture are we looking at
  // simData: simulation parameters (eg. nbDoses)
  // simSettings: simulation constants
  get(key, getter, culture, simData, simSettings) {
    const {
      FRAC_MS, IRV, RST_BIO, NST, NAE, TenP2o5ParGrain, TenP2o5ParPaille, TenK2oParGrain, TenK2oParPaille
    } = simSettings.calc_constants;
    const mecaApplicationConstants = simSettings.meca_applications.reduce((memoMeca, meca) => ({
      ...memoMeca,
      [meca.key]: meca.calc_constant
    }), {});

    const RDTR = parseFloat(simData.productionInfos.yield);
    const SPAR = parseFloat(simData.productionInfos.surface);

    const GES_MECA_PHYTO = getter("GES_MECA_PHYTO").value || 0;
    const GES_MECA_FERTI = getter("GES_MECA_FERTI").value || 0;

    const PRR = parseFloat(simData.expResPRR);
    const NORGATOT = parseFloat(simData.nOrgaTot) || 0;
    const NMINERALTOT = parseFloat(simData.nMineralTot) || 0;

    const AEMS = formulae.aems(RDTR, FRAC_MS, IRV);
    const GES_INTERVENTION_MECA = formulae.interventionMeca(simData.meca, mecaApplicationConstants);

    const MECA_TOT = formulae.mecaTot(GES_MECA_PHYTO, GES_MECA_FERTI, GES_INTERVENTION_MECA);

    const EXPORT_P = formulae.exportP(RDTR, TenP2o5ParGrain, TenP2o5ParPaille, PRR, AEMS, FRAC_MS);
    const EXPORT_K = formulae.exportK(RDTR, TenK2oParGrain, TenK2oParPaille, PRR, AEMS, FRAC_MS);
    const gesProdEng = formulae.gesProdEng(NMINERALTOT, NORGATOT, EXPORT_P, EXPORT_K);
    const gesUtilEngSansRes = formulae.gesUtilEngSansRes(NMINERALTOT, NORGATOT);
    const QTE_NRES = formulae.qteNRes(AEMS, PRR, NAE, RDTR, FRAC_MS, RST_BIO, NST);
    const EN2ORES = formulae.en2oRes(QTE_NRES);
    const GES_RES = formulae.gesRes(EN2ORES);

    const GES_TOTAL = formulae.gesTotal(
      gesProdEng || getter("GES_ProdEng").value,
      gesUtilEngSansRes || getter("GES_UtilEng_SANS_RES").value,
      GES_RES || getter("GES_RES").value,
      MECA_TOT || getter("GES_MECA").value,
      getter("GES_ProdPhyto").value,
      getter("GES_ProdSem").value,
      getter("GES_Irrig").value
    );
    const EQUIV = formulae.equivCO2(GES_TOTAL, SPAR);
    const DIST = formulae.dist(EQUIV) || getter("DIST").value;

    const PROD = formulae.prod(culture, SPAR, RDTR);

    const value = {
      GES_UtilEng_SANS_RES: gesUtilEngSansRes,
      GES_TOTAL,
      GES_INTERVENTION_MECA,
      DIST,
      EQUIV,
      PROD,
      AEMS,
      GES_RES,
      GES_ProdEng: gesProdEng,
      GES_MECA: MECA_TOT,
      QTE_NRES,
      EN2ORES,
      EXPORT_P,
      EXPORT_K,
      NMINERALTOT,
      NORGATOT
    }[key];

    if (!value && window?.debug) {
      console.log(`Sim handler for ${key} returned no value`);
    }
    if (value === getter(key).value) {
      return { key, updated_at: "", value: null };
    }
    return { key, updated_at: "", value };
  }
};
