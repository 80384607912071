const dict = {
  mais: "maïs",
  ble: "blé",
  // TODO get rid of this when we API is ready
  cereal: "blé"
};

export default function (key) {
  return dict[key] || key;
}
