import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState("verticalMenu", ["currentPeriod", "currentCooperative", "currentGesCulture", "currentHveCulture"]),
    ...mapGetters("admin", ["getFarmById"])
  },
  methods: {
    getGlobalHveAudit() {
      this.$http.getGlobalHveAudit({ period: this.currentPeriod, culture: this.currentHveCulture })
        .then(({ data }) => this.$store.commit("global/SET_HVEAUDIT", data))
        .catch(console.warn);
    },
    getCooperativeHveAudit() {
      this.$http.getCooperativeHveAudit({ cooperative_id: this.currentCooperative.id, period: this.currentPeriod, culture: this.currentHveCulture })
        .then(({ data }) => this.$store.commit("cooperative/SET_HVEAUDIT", data))
        .catch(console.warn);
    },
    getCooperativeGesAudit() {
      this.$store.commit("cooperative/SET_GESAUDIT_STATUS", "pending");

      return this.$http.getCooperativeGesAudit({ cooperative_id: this.currentCooperative.id, period: this.currentPeriod, culture: this.currentGesCulture })
        .then(({ data }) => {
          this.$store.commit("cooperative/SET_GESAUDIT", data);
          this.$store.commit("cooperative/SET_GESAUDIT_STATUS", "success");
        })
        .catch(e => {
          if (e.response && e.response.status === 410) {
            this.$store.commit("cooperative/SET_GESAUDIT_STATUS", "unavailable");
          } else {
            this.$store.commit("cooperative/SET_GESAUDIT_STATUS", "error");
          }
        });
    },
    getAudits() {
      this.getGlobalHveAudit();
      if (this.currentCooperative) {
        this.getCooperativeHveAudit();
      }
      if (this.currentGesCulture) {
        this.getCooperativeGesAudit();
      }
    }
  }
};
