<template>
  <b-card>
    <h3>Bilan GES</h3>
    Cet indicateur vise à suivre les émissions de gaz à effet de serre
    participant au réchauffement climatique. En agriculture, les principaux gaz
    à effet de serre émis sont le dioxyde de carbone (CO2), le méthane (CH4) et
    le protoxyde d’azote (N2O). Ces gaz ont des pouvoirs de réchauffement
    climatique variables et pour pouvoir les sommer, une référence unique est
    utilisée : les kg. équivalent CO2. Les facteurs de caractérisation suivants
    sont utilisés pour convertir les émissions de GES par gaz sur cette
    référence :
    <ul class="pt-1">
      <li>CO2 --> 1</li>
      <li>CH4 --> 28</li>
      <li>N2O --> 265</li>
    </ul>
    Pour exemple, le N2O est un gaz notamment émis lors de l'épandage d'engrais
    azotés. Lors qu'1 kg de N2O est émis, cela équivaut à 265 kg de CO2. En
    production végétale, la fabrication et l’usage des fertilisants sont les
    principaux postes contributeurs des émissions de GES.
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";

export default {
  components: {
    BCard
  }
};
</script>
