<template>
  <div>
    <b-form-checkbox
      v-for="indicator in indicators"
      :key="indicator.handle"
      v-model="checkboxSelected"
      :value="indicator.handle"
      :disabled="indicator.tag === 'N.C.'"
      class="pb-1"
      @change="$emit('change', $event)"
    >
      {{ indicator.legend }}
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox
  },
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    indicators: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkboxSelected: []
    };
  },
  created() {
    this.indicators.forEach(indicator => {
      if (indicator.tag !== "N.C.") {
        this.checkboxSelected.push(indicator.handle);
      }
    });
    this.$emit("change", this.checkboxSelected);
  }
};
</script>
