<template>
  <div class="VerticalGES">
    <div class="VerticalGES__step-container">
      <div class="VerticalGES__bar" />
      <div class="VerticalGES__sections">
        <div
          v-for="section in sections"
          :key="section.step"
          class="VerticalGES__section"
        >
          <div class="legend">
            <div class="step">
              {{ section.step }}
            </div>
            <div class="value">
              {{ section.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="VerticalGES__indicator-container">
      <div
        v-for="indicator in indicators"
        v-show="indicator.tag !== 'N.C.'"
        :key="indicator.handle"
        class="VerticalGES__indicator"
        :class="indicator.handle"
        :style="indicatorBottomPercentage(indicator.value)"
      >
        <div class="tag">
          {{ indicator.tag }}
        </div>
        <div
          class="legend"
          :style="{'display': indicator.legendAlwaysShown ? 'flex' : 'none'}"
        >
          {{ indicator.legend }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    indicators: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sections: [
        {
          step: "D",
          value: "3255+"
        },
        {
          step: "C",
          value: "2830 à 3255"
        },
        {
          step: "B",
          value: "2340 à 2830"
        },
        {
          step: "A",
          value: "<2340"
        }
      ]
    };
  },
  methods: {
    indicatorBottomPercentage(value) {
      // return css property computed on the VerticalGES sections
      if (value >= 0 && value < 2340) {
        return `bottom: ${(value * 25) / 2340}%;`;
      }

      if (value >= 2340 && value < 3255) {
        const relativeValue = value - 2340;
        const relativeUpperBound = 3255 - 2340;
        return `bottom: ${(relativeValue * 50) / relativeUpperBound + 25}%;`;
      }

      if (value >= 3255) {
        const relativeValue = value - 3225;
        const relativeUpperBound = 5000 - 3225;
        return (relativeValue * 25) / relativeUpperBound + 75 >= 100
          ? "bottom: 100%"
          : `bottom: ${(relativeValue * 25) / relativeUpperBound + 75}%;`;
      }

      return "display: none;";
    }
  }
};
</script>

<style lang="scss" scoped>
.VerticalGES {
  height: 500px;
  display: flex;

  &__step-container {
    position: relative;
    display: flex;
    left: -42px;
  }

  &__bar {
    width: 30px;
    position: relative;
    left: 80px;
    border-radius: 20px;
    background: linear-gradient(0deg, #6ac85b 0%, #faff00 49.74%, #f02a2a 100%);
  }

  &__sections {
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    text-align: center;
  }

  &__section {
    width: 120px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -35px;
    padding-right: 20px;

    .legend {
      transform: rotate(-90deg);
      position: relative;
      left: 15px;

      .step {
        transform: rotate(90deg);
      }
    }
  }

  &__section:not(:last-child) {
    border-bottom: 3px solid #fff;
  }

  &__indicator-container {
    width: 100%;
    position: relative;
    left: -42px;

    .coop-average {
      font-weight: bold;
      width: 128%;
      z-index: 10;

      .legend {
        display: flex;
      }
    }

    .global-average {
      .legend {
        font-weight: bold;
      }
    }

    .coop-min {
      .tag {
        color: green;
        font-weight: bold;
      }
    }

    .coop-max {
      .tag {
        color: red;
        font-weight: bold;
      }
    }

    .ref,
    .ref-bio {
      .legend {
        font-style: italic;
      }
    }

    .farm-measure {
      z-index: 1000;
      font-weight: bold;
    }

    .farm-simulation {
      z-index: 1001;
      box-shadow: 0 8px 6px rgb(128 128 128 / 10%), 0 2px 6px rgb(128 128 128 / 20%);
      font-weight: bold;
    }
  }

  &__indicator {
    font-size: 11px;
    width: auto;
    max-width: 280px;
    overflow-wrap: normal;
    margin: 0;
    box-shadow: 0 2px 3px rgba(128, 128, 128, 0.1), 0 2px 6px rgba(128, 128, 128, 0.2);
    background-color: #fff;
    padding: 0.5em;
    border-radius: 3px;
    display: flex;
    align-items: center;
    position: absolute;
    transform: translateY(50%);
    cursor: pointer;

    .tag {
      white-space: nowrap;
      flex: 1;
      width: auto;
    }

    .legend {
      padding-left: 9px;
      display: none;
    }
  }

  &__indicator::before {
    content: "";
    position: absolute;
    left: -5px;
    bottom: 50%;
    border: 7px solid transparent;
    border-width: 20px 7px 12px 7px;
    border-top-color: #fff;
    transform: translateY(50%) translateX(-100%) rotateZ(90deg);
  }

  &__indicator:hover {
    z-index: 100000;

    .legend {
      display: flex !important;
    }
  }
}

.hidden {
  display: none;
}
</style>
