<template>
  <div
    style="max-width: 142rem; margin: auto;"
  >
    <div
      v-if="[coopGesAuditStatus, gesDataStatus].some(v => v === 'pending')"
      class="d-flex justify-content-center mb-3"
    >
      <b-spinner
        variant="primary"
        label="Loading..."
      />
    </div>
    <b-alert
      class="mb-3"
      :show="gesDataStatus === 'error'"
      variant="danger"
    >
      <div class="alert-body">
        Erreur lors de la récupération des données GES
      </div>
    </b-alert>
    <b-row
      v-if="gesDataStatus === 'unavailable'"
      align-v="baseline"
      class="mb-2"
    >

      <b-col cols="12">
        <div class="d-flex justify-content-end align-items-center">
          <b-dropdown
            class="custom-dropdown ml-1"
            variant="outline-primary"
            :text="localize(currentGesCulture)"
          >
            <b-dropdown-item
              v-for="(culture, index) in farmHveAudit.farm.ges_cultures"
              :key="index"
              @click="$store.commit('verticalMenu/SET_GES_CULTURE', culture)"
            >
              {{ localize(culture) }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <b-alert
      class="mb-3"
      :show="gesDataStatus === 'unavailable'"
      variant="warning"
    >
      <div class="alert-body">
        Aucune donnée disponible pour cette culture sur cette période
      </div>
    </b-alert>
    <template v-if="[coopGesAuditStatus, gesDataStatus].every(v => v === 'success')">
      <b-row
        align-v="baseline"
        class="mb-2"
      >
        <b-col cols="8">
          <h2>Tableau de synthèse Gaz à effet de serre</h2>
          <b-button
            class="btn-outline-secondary"
            size="sm"
            @click="toggleSimulationMode"
          >
            <span v-if="!simulationMode">Mode simulation</span>
            <span v-else>Mode classique</span>
          </b-button>
        </b-col>

        <b-col cols="4">
          <div class="d-flex justify-content-end align-items-center">
            <b-link @click="toggleGesHelp">
              <feather-icon
                v-if="isGesHelpShown"
                icon="XCircleIcon"
                class="text-primary"
                size="16"
              />
              <feather-icon
                v-else
                icon="HelpCircleIcon"
                class="text-primary"
                size="16"
              />
              Qu'est-ce que le bilan GES ?
            </b-link>
            <b-dropdown
              class="custom-dropdown ml-1"
              variant="outline-primary"
              :text="localize(currentGesCulture)"
            >
              <b-dropdown-item
                v-for="(culture, index) in farmHveAudit.farm.ges_cultures"
                :key="index"
                @click="$store.commit('verticalMenu/SET_GES_CULTURE', culture)"
              >
                {{ localize(culture) }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="simulationMode ? 'auto' : 8">
          <b-row>
            <b-col :cols="simulationMode ? 5 : 12">
              <h3 class="mb-1">
                Les différents postes d’émission
                <small>Mis à jour le {{ dayjs(emissionsListItems[0].updated_at).format("DD/MM/YYYY") }}</small>
              </h3>
              <b-card
                v-for="emission in emissionsListItems"
                :key="emission.indicator.key"
                align-v="center"
                class="mb-1 emission-line"
              >
                <b-row>
                  <b-col cols="7 d-flex align-items-center">
                    <p class="h5 mb-0">
                      {{ emission.indicator.label }}
                    </p>
                  <!-- <div class="emission-key">
                    Ref: {{ emission.indicator.key }}
                  </div> -->
                  </b-col>
                  <b-col cols="5">
                    <b-row>
                      <b-col cols="6">
                        <div class="emission-value">
                          {{ prettyNum(emission.value) }}
                        <!-- <span class="emission-percent">({{ prettyNum(emission.value / measure("GES_TOTAL").value* 100) }}%)</span> -->
                        </div>
                        <div class="unit">
                          t eq.CO2/ha
                        </div>
                      </b-col>
                      <b-col
                        v-if="simulatedMeasure(emission.indicator.key).value"
                        cols="6"
                      >
                        <div
                          class="emission-value--sim"
                        >
                          {{ prettyNum(simulatedMeasure(emission.indicator.key).value / 1000) }}
                        </div>
                        <div class="unit">
                          t eq.CO2/ha
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col :cols="simulationMode ? 7 : 12">

              <b-row align-v="baseline">
                <b-col cols="8">
                  <h3 class="mb-1">
                    Production de votre exploitation
                  </h3>
                </b-col>
                <b-col
                  cols="4"
                  class="text-right"
                >
                  <b-link @click="resetSimulationProduction">
                    <feather-icon icon="RefreshCwIcon" />
                    Réinitialiser la simulation
                  </b-link>
                </b-col>
              </b-row>
              <b-row class="d-flex align-items-stretch">
                <b-col class="d-flex align-items-stretch">
                  <b-card>
                    <h4 class="h6">
                      Surface de {{ localize(currentGesCulture) }} sur l'exploitation
                    </h4>
                    <b-row align-v="center">
                      <b-col cols="6">
                        <b-form-input
                          v-model="simulation.productionInfos.surface"
                          type="text"
                          placeholder="150ha"
                          class="production-input"
                        />
                      </b-col>
                      <b-col cols="6">
                        ha
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col class="d-flex align-items-stretch">
                  <b-card>
                    <h4 class="h6">
                      Rendement sur l'exploitation
                    </h4>
                    <b-row align-v="center">
                      <b-col cols="6">
                        <b-form-input
                          v-model="simulation.productionInfos.yield"
                          type="text"
                          placeholder="150ha"
                          class="production-input"
                        />
                      </b-col>
                      <b-col cols="6">
                        q/ha
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col class="d-flex align-items-stretch">
                  <b-card>
                    <h4 class="h6">
                      Rendement moyen national
                    </h4>
                    <b-row align-v="center">
                      <b-col cols="6">
                        <!-- TODO -->
                        <b-form-input
                          type="text"
                          placeholder="150ha"
                          value="68,3"
                          readonly
                          class="production-input"
                        />
                      </b-col>
                      <b-col cols="6">
                        q/ha
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>

              <b-row align-v="baseline">
                <b-col cols="8">
                  <h3 class="mb-1">
                    Simulation de mes leviers d’action
                  </h3>
                </b-col>
                <b-col
                  cols="4"
                  class="text-right"
                >
                  <b-link @click="resetSimulationNitrates">
                    <feather-icon icon="RefreshCwIcon" />
                    Réinitialiser la simulation
                  </b-link>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-for="(nitrate, idx) in nitrates"
                  :key="nitrate.key"
                  class="d-flex align-items-stretch"
                >
                  <b-card>
                    <h4 class="h5">
                      {{ measure(nitrate.key).indicator.label }}
                    </h4>
                    <b-row>
                      <b-col class="simulation-col">
                        <div class="emission-value">
                          {{ prettyNum(measure(nitrate.key).value) }}
                        </div>
                        <div class="unit--sim">
                          kgN/ha
                        </div>
                      </b-col>
                      <b-col class="simulation-col">
                        <b-form-input
                          v-model="simulation[nitrate.simulationKey]"
                          type="number"
                          :min="0"
                          placeholder="0"
                          class="production-input"
                        />
                        <span class="unit--sim">kgN/ha</span>
                      </b-col>
                    </b-row>

                    <b-link
                      v-if="idx === 0"
                      class="d-flex align-items-center"
                      @click="toggleNitrateHelp"
                    >
                      <feather-icon
                        v-if="isNitrateHelpShown"
                        icon="XCircleIcon"
                        style="margin-right: 6px;"
                      />
                      <feather-icon
                        v-else
                        icon="HelpCircleIcon"
                        style="margin-right: 6px;"
                      />
                      À propos des doses d'azote
                    </b-link>
                    <p
                      v-if="isNitrateHelpShown && idx === 0"
                      class="mt-1"
                    >
                      En production végétale, la fabrication et l’usage des fertilisants sont les principaux postes contributeurs des émissions de GES. En fonction des cultures, les fertilisants, et en particulier les fertilisants d’origine minérale, peuvent représenter jusqu’à 80% des émissions de GES. En réduisant la dose d’azote apportée sous forme minérale, les émissions liées à la fabrication des engrais azotés et ceux liées à l’utilisation des engrais azotés d’origine minérale diminuent également. Une solution pour réduire les émissions de GES est de substituer une partie de l’azote minéral par de l’azote organique. <br><br>
                      NB : pour la simulation, une hypothèse simplificatrice a été faite ici considérant que la dose d’azote minérale et les indicateurs « Emissions des GES liées à la fabrication des engrais azotés d’origine minérale » et « Emissions des GES liées à l’utilisation des engrais azotés d’origine minérale » varient de manière linéaire. La même hypothèse a été faire entre la dose d’azote organique et l’indicateur « Emissions des GES liées à l’utilisation des engrais azotés d’origine organique
                    </p>

                  </b-card>
                </b-col>

                <b-col
                  v-if="currentGesCulture !== 'vigne'"
                  class="d-flex align-items-stretch"
                >
                  <b-card class="d-flex align-items-stretch">

                    <h4 class="h5">
                      Gestion des résidus
                    </h4>
                    <b-form-radio-group
                      v-model="simulation.expResPRR"
                      :options="$options.expResPRR"
                      button-variant="outline-primary"
                      size="sm"
                      name="radio-btn-outline"
                      class="mb-1"
                      buttons
                    />
                    <b-link
                      class="d-flex align-items-center"
                      @click="toggleResidueHelp"
                    >
                      <feather-icon
                        v-if="isResidueHelpShown"
                        icon="XCircleIcon"
                        style="margin-right: 6px;"
                      />
                      <feather-icon
                        v-else
                        icon="HelpCircleIcon"
                        style="margin-right: 6px;"
                      />
                      À propos de la gestion des résidus
                    </b-link>
                    <p
                      v-if="isResidueHelpShown"
                      class="mt-1"
                    >
                      Les résidus de culture contiennent de l’azote. Lors de leur décomposition, une partie de l’azote contenue dans les pailles se volatilise sous forme de N2O. Lorsque les pailles sont exportées (du moins une partie, car on considère que 20 % des pailles restent au sol), les émissions associées sont imputées à un autre système. Le fait de les exporter permet de réduire les émissions de GES associées à la culture étudiée. Néanmoins, enfouir les pailles permet d’enrichir le sol et donc de réduire la dose de fertilisant sur la culture suivante, ce qui permet de réduire les émissions de GES associées. <br><br>
                      NB : pour la simulation, une hypothèse simplificatrice a été faite ici considérant un facteur entre le fait d’exporter les pailles ou de les laisser sur la parcelle.
                    </p>

                  </b-card>
                </b-col>
              </b-row>

              <b-row
                align-v="baseline"
                class="mb-1"
              >
                <b-col>
                  <h4>Travail du sol</h4>
                </b-col>
                <b-col class="text-right">
                  <b-link @click="resetSimulationMeca">
                    <feather-icon icon="RefreshCwIcon" />
                    Réinitialiser la simulation
                  </b-link>
                </b-col>
              </b-row>
              <b-card>
                <b-row
                  align-v="center"
                >
                  <b-col
                    v-for="meca in mecaListItems"
                    :key="currentGesCulture + '.' + meca.key"
                    cols="6"
                    class="mb-1"
                  >
                    <b-row>
                      <b-col cols="6">
                        <h5 class="m-0">
                          {{ meca.label }}
                        </h5>
                        <template v-if="meca.description">
                          {{ meca.description }}
                        </template>
                      </b-col>
                      <b-col
                        cols="6"
                        class="incremental-input"
                      >
                        <b-form-spinbutton
                          v-model="simulation.meca[meca.key]"
                          :min="0"
                          :max="1000"
                        >
                          <template #decrement>
                            <b-button
                              variant="primary"
                              size="sm"
                              class="btn--left"
                            >
                              <feather-icon
                                icon="MinusIcon"
                                size="12"
                              />
                            </b-button>
                          </template>
                          <template #increment>
                            <b-button
                              variant="primary"
                              size="sm"
                              class="btn--right"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="12"
                              />
                            </b-button>
                          </template>
                        </b-form-spinbutton>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>

            </b-col>
          </b-row>
        </b-col>

        <b-col
          v-if="!simulationMode"
          cols="4"
        >
          <GesHelper v-if="isGesHelpShown" />
          <b-card
            class="mt-3"
            :title="'Total des émissions pour la culture de ' + localize(currentGesCulture)"
          >
            <b-row>
              <b-col cols="9">
                <b-row>
                  <b-col
                    cols="12"
                    class="h2 mb-0"
                  >
                    {{ prettyNum(measure("GES_TOTAL").value) }}t
                  </b-col>
                  <b-col
                    cols="12"
                    style="font-size: 12px;"
                  >
                    eq.CO2/ha
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="3">
                <div class="icon-container bg-light-info text-center">
                  <feather-icon
                    icon="CloudIcon"
                    class="text-info"
                    size="24"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col
                cols="9"
                class="equiv-trajet"
              >
                <div class="h2 mb-0">
                  {{ measure("EQUIV_TRAJET").value }}*
                </div>
                <div class="text-sm">
                  *Équivalent en nombre de trajets <b>Paris/Nice (Via A6 / A7)</b>
                  <div class="trajet-source">
                    Source: Google Maps
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="icon-container bg-light-warning text-center">
                  <feather-icon
                    icon="TruckIcon"
                    class="text-warning"
                    size="24"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row
              align-v="center"
              class="mt-2"
            >
              <b-col cols="9">
                <p class="mb-0">
                  Soit une production de
                </p>
                <div class="h2 mb-0">
                  {{ simulatedMeasure("PROD").value }}
                  <div
                    v-if="currentGesCulture === 'ble'"
                    class="prod-legend"
                  >
                    * Sacs de farine de 25kg
                  </div>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="icon-container bg-light-info text-center">
                  <feather-icon
                    icon="ShoppingBagIcon"
                    class="text-success"
                    size="28"
                  />
                </div>
              </b-col>
            </b-row>

          </b-card>
          <b-card class="Ges-card">
            <GesGauge
              :indicators="filteredIndicators"
              class="mb-3"
            />
            <div class="Filter-switch">
              Filtre
              <b-form-checkbox
                v-model="isGesFilterShown"
                class="custom-control-primary"
                switch
              />
            </div>
            <GesFilter
              v-if="isGesFilterShown"
              v-model="indicatorFilter"
              :indicators="indicators"
            />
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormSpinbutton,
  BFormRadioGroup,
  BDropdown,
  BDropdownItem,
  BAlert,
  BSpinner
} from "bootstrap-vue";
import { mapState } from "vuex";
import GesHelper from "@/components/ges/GesHelper.vue";
import GesGauge from "@/components/ges/GesGauge.vue";
import GesFilter from "@/components/ges/GesFilter.vue";
import simulator from "@/components/GesSimulator/simulator";
import dayjs from "dayjs";
import localize from "@/utils/localize";
import store from "@/store";
import formulae from "@/components/GesSimulator/calculus/formulae";
import sharedAuditsMixins from "@/mixins/sharedAudits";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCard,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormSpinbutton,
    BFormRadioGroup,
    GesHelper,
    GesGauge,
    GesFilter,
    BDropdown,
    BDropdownItem,
    BAlert,
    BSpinner
  },
  mixins: [sharedAuditsMixins],

  expResPRR: [
    { text: "Non exportés", value: 0 },
    { text: "Semi exportés", value: 40 },
    { text: "Exportés", value: 80 }
  ],

  data() {
    return {
      gesData: [],
      gesDataStatus: "pending",
      simSettings: null,
      simulation: null,
      isGesHelpShown: false,
      isNitrateHelpShown: false,
      isResidueHelpShown: false,
      isGesFilterShown: true,
      indicatorFilter: [],
      nitrates: [
        {
          key: "NMINERALTOT",
          simulationKey: "nMineralTot"
        },
        {
          key: "NORGATOT",
          simulationKey: "nOrgaTot"
        }
      ],
      simulationMode: false
    };
  },
  computed: {
    ...mapState("farm", { farmHveAudit: "hveAudit" }),
    ...mapState("cooperative", { coopGesAudit: "gesAudit" }),
    ...mapState("cooperative", { coopGesAuditStatus: "gesAuditStatus" }),
    ...mapState("verticalMenu", ["currentGesCulture", "currentPeriod"]),

    indicators() {
      return [{
        handle: "farm-simulation",
        legend: "Vos émissions (SIMULATION)",
        legendAlwaysShown: true,
        tag: `${this.prettyNum(this.simulatedMeasure("GES_TOTAL").value / 1000)}t eq.CO2/ha`,
        value: this.simulatedMeasure("GES_TOTAL").value
      }, {
        handle: "farm-measure",
        legend: "Vos émissions",
        legendAlwaysShown: true,
        tag: `${this.prettyNum(this.measure("GES_TOTAL").value / 1000)}t eq.CO2/ha`,
        value: this.measure("GES_TOTAL").value
      }, ...this.coopGesAudit.data];
    },
    filteredIndicators() {
      if (this.indicatorFilter) {
        const filteredIndicators = [];
        this.indicators.forEach(indicator => {
          if (this.indicatorFilter.includes(indicator.handle)) {
            filteredIndicators.push(indicator);
          }
        });

        return filteredIndicators;
      }
      return null;
    },

    emissionsListItems() {
      return this.gesData.filter(d => d.emissions_list);
    },
    mecaListItems() {
      return this.simSettings.meca_applications.filter(m => !m.read_only);
    }
  },
  watch: {
    currentGesCulture() {
      this.getFarmGesAudit();
      this.getCooperativeGesAudit();
    },
    currentPeriod() {
      this.getFarmGesAudit();
    }
  },
  created() {
    this.getFarmGesAudit();
  },
  methods: {
    toggleSimulationMode() {
      store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", !this.simulationMode);
      store.commit("appConfig/UPDATE_CONTENT_WIDTH", !this.simulationMode ? "full" : "boxed");
      this.simulationMode = !this.simulationMode;
    },
    toggleGesHelp() {
      this.isGesHelpShown = !this.isGesHelpShown;
    },
    toggleNitrateHelp() {
      this.isNitrateHelpShown = !this.isNitrateHelpShown;
    },
    toggleResidueHelp() {
      this.isResidueHelpShown = !this.isResidueHelpShown;
    },
    initSimulation() {
      this.simulation = {
        nOrgaTot: 0,
        nMineralTot: 0,
        expResPRR: null,
        productionInfos: {
          surface: this.measure("SPAR").value,
          yield: this.measure("RDTR").value
        },
        meca: this.simSettings.meca_applications.reduce((memoMeca, meca) => ({
          ...memoMeca,
          [meca.key]: meca.default_applications || 0
        }), {})
      };
    },

    resetSimulationMeca() {
      this.simulation = {
        ...this.simulation,
        meca: this.simSettings.meca_applications.reduce((memoMeca, meca) => ({
          ...memoMeca,
          [meca.key]: meca.default_applications || 0
        }), {})
      };
    },

    resetSimulationProduction() {
      this.simulation = {
        ...this.simulation,
        productionInfos: {
          surface: this.measure("SPAR").value,
          yield: this.measure("RDTR").value
        }
      };
    },

    resetSimulationNitrates() {
      this.simulation = {
        ...this.simulation,
        expResPRR: null,
        nOrgaTot: 0,
        nMineralTot: 0
      };
    },
    prettyNum(num) {
      if (num === 0) {
        return 0;
      }
      if (!num) {
        return "";
      }
      if (Number.isNaN(num)) {
        return "NaN";
      }
      return num.toLocaleString("fr", { maximumFractionDigits: 2 });
    },

    measure(key) {
      const measure = this.gesData.find(m => m.indicator.key === key);

      if (measure) {
        return measure;
      }
      if (key === "RDTR") {
        return { key: "RDTR", value: this.simSettings.default_rdtr, updated_at: null };
      }
      if (key === "EQUIV_TRAJET") {
        const gesTotal = this.gesData.find(m => m.indicator.key === "GES_TOTAL").value * 1000;
        return { key: "EQUIV_TRAJET", value: formulae.equivTrajet(gesTotal) };
      }
      return { key, value: null, updated_at: null };
    },
    simulatedMeasure(key) {
      return simulator.get(key, this.measure, this.currentGesCulture, this.simulation, this.simSettings);
    },
    getFarmGesAudit() {
      this.gesDataStatus = "pending";
      this.$http.getFarmGesAudit({ farm_id: this.$route.params.farm_id, period: this.currentPeriod, culture: this.currentGesCulture })
        .then(({ data }) => {
          if (data.measures.length === 0) {
            this.gesDataStatus = "unavailable";
          } else {
            this.gesData = data.measures;
            this.simSettings = data.simdata;
            this.initSimulation();
            this.gesDataStatus = "success";
          }
        })
        .catch(() => {
          this.gesDataStatus = "error";
        });
    },
    dayjs,
    localize
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
}

.simulation-col {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 0.5em;

  .form-control,
  .emission-value {
    text-align: right;
    min-width: 4em;
    max-width: 8em;
  }
}

.prod-legend {
  font-size: 11px;
}

.simulation-modifier {
  width: 95%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  background-color: #f8f8f8;
  font-size: 15px;

  & .btn {
    padding: 0.3rem;

    &--left {
      position: absolute;
      left: 0;
      transform: translateX(-50%);
    }

    &--right {
      position: absolute;
      right: 0;
      transform: translateX(50%);
    }
  }
}

.btn {
  font-size: 11px;
}

.incremental-input {
  max-width: 12rem;
}

.emission-line .card-body {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.emission-value {
  font-size: 20px;
  font-weight: 700;

  &--sim {
    opacity: 1;
    font-size: 20px;
    font-weight: 700;
  }

  &--fallback {
    opacity: 0.4;
    font-size: 20px;
    font-weight: 700;
  }
}

.emission-percent {
  font-size: 11px;
  font-weight: bold;
}

.emission-key {
  font-size: 10px;
}

.unit {
  font-size: 11px;

  &--sim {
    margin-left: 6px;
  }
}

.production-input {
  text-align: end;
  padding: 0 6px 0 0;
}

.icon-container {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.Ges-card {
  overflow: hidden;
  position: sticky;
  top: 95px;
}

.Filter-switch {
  display: flex;
  margin-bottom: 1rem;

  & .custom-control-primary {
    margin-left: 0.5rem;
  }
}

.trajet-source {
  opacity: 0;
  font-style: italic;
  font-size: 0.8rem;
}

.equiv-trajet:hover {
  .trajet-source {
    opacity: 1;
  }
}
</style>
