/* eslint-disable no-mixed-operators */

const prettyNum = num => num.toLocaleString("fr", { maximumFractionDigits: 2 });

// Abstract GES Calculus, not taking data origin into account
export default {
  prod: (culture, SPAR, RDTR) => {
    if (culture === "mais") {
      return `${prettyNum(SPAR * 5000 / 1000)} tonnes de popcorn`;
    }
    if (culture === "colza" || culture === "tournesol") {
      return `${prettyNum(SPAR * RDTR * 100 / 4)}HL d'huile`;
    }
    if (culture === "ble") {
      return `${prettyNum((SPAR * RDTR / 10 * 0.75 * 25000) / 25)} sacs de farine`;
    }
    if (culture === "vigne") {
      return `${prettyNum(SPAR * RDTR * 0.06375 * 100 / 0.75)} bouteilles de vin`;
    }
    return null;
  },
  gesUtilEngSansRes: (NMINERALTOT, NORGATOT) => NMINERALTOT * 8.5 + NORGATOT * 7.261,

  dist: EQUIV => EQUIV / 12,

  equivCO2: (GES_TOTAL, SPAR) => GES_TOTAL * SPAR,

  gesTotal: (gesProdEng, gesUtilEngSansRes, gesRes, mecaTot, gesProdPhyto, gesProdSem, gesIrrig) => gesProdEng + gesUtilEngSansRes + gesRes + mecaTot + gesProdPhyto + gesProdSem + gesIrrig,

  exportK: (RDTR, TenK2oParGrain, TenK2oParPaille, PRR, AEMS, FRAC_MS) => RDTR * TenK2oParGrain + PRR / 100 * AEMS / 1000 * 1 / FRAC_MS * TenK2oParPaille,

  exportP: (RDTR, TenP2o5ParGrain, TenP2o5ParPaille, PRR, AEMS, FRAC_MS) => RDTR * TenP2o5ParGrain + PRR / 100 * AEMS / 1000 * 1 / FRAC_MS * TenP2o5ParPaille,

  gesProdEng: (NMINERALTOT, NORGATOT, EXPORT_P, EXPORT_K) => {
    if (NMINERALTOT > 0 || NORGATOT > 0) {
      return NMINERALTOT * 4.64626811;
    }

    return EXPORT_P * 1.0762271 + EXPORT_K * 0.79282852;
  },

  gesRes: EN2ORES => EN2ORES * 265,

  en2oRes: QTE_N_RES => QTE_N_RES * 0.006 * 44 / 28,

  qteNRes: (AEMS, PRR, NAE, RDTR, FRAC_MS, RST_BIO, NST) => AEMS * (1 - PRR / 100) * NAE + (AEMS + RDTR * 100 * FRAC_MS) * RST_BIO * NST,

  aems: (RDTR, FRAC_MS, IRV) => RDTR * 100 * FRAC_MS * (1 - IRV) / IRV,

  mecaTot: (GES_MECA_PHYTO, GES_MECA_FERTI, INT_MECA) => GES_MECA_PHYTO + GES_MECA_FERTI + INT_MECA,

  interventionMeca: (applicationsByMecaKey, mecaApplicationConstants) => Object.keys(mecaApplicationConstants).reduce((memo, key) => memo + ((applicationsByMecaKey[key] || 0) * mecaApplicationConstants[key]), 0),

  equivTrajet: GES_TOTAL => Math.round((GES_TOTAL * 5.62 / 932) * 2) * 0.5

};
